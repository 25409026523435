var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.productData)?_c('div',{staticClass:"content"},[_c('section',{staticClass:"product"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[(
                            _vm.productData.globalPattern &&
                                _vm.productData.globalPattern.image
                        )?_c('img',{attrs:{"src":_vm.productData.globalPattern.image}}):_c('img',{attrs:{"src":_vm.productData.kepnev}})]),_c('div',{staticClass:"col-lg-8 details"},[_c('div',{staticClass:"producthead"},[_c('h3',{staticClass:"item-name"},[_vm._v(" "+_vm._s(_vm.productData.emarka)+" "+_vm._s(_vm.productData.pattern)+" "+_vm._s(_vm.productData.li)+_vm._s(_vm.productData.si)+" "+_vm._s(_vm.productData.xlfr)+" "+_vm._s(_vm.productData.m1nev)+" ")]),_c('span',{staticClass:"item-type"},[_vm._v(_vm._s(_vm.productData.width)+"/"+_vm._s(_vm.productData.profile)+"R"+_vm._s(_vm.productData.coll)+" "),_c('br'),_vm._v(" DOT SZÁM: "+_vm._s(_vm.productData.co_klt)+" ")])]),_c('div',{staticClass:"row mb mb-5"},[_c('div',{staticClass:"col-sm-6 mainCol"},[_c('span',{staticClass:"item-price sale",class:{
                                    underlined: _vm.productData.szemar !== 0
                                }},[_vm._v(" Ár: "+_vm._s(_vm.productData.calculated_special_price)+" / db ")]),(
                                    parseInt(_vm.productData.tat) <= 6 ||
                                        _vm.productData.tat === null ||
                                        isNaN(_vm.productData.tat)
                                )?[(
                                        parseInt(_vm.productData.kulso_raktar) >
                                            0 &&
                                            (parseInt(_vm.productData.tat) <=
                                                0 ||
                                                isNaN(
                                                    parseInt(
                                                        _vm.productData.tat
                                                    )
                                                ))
                                    )?_c('span',{staticClass:"lastOnes"},[_vm._v(" Beszállítói készlet")]):(
                                        parseInt(_vm.productData.tat) > 0
                                    )?_c('span',{staticClass:"lastOnes"},[_vm._v(" Utolsó "+_vm._s(_vm.productData.tat)+" db!")]):_vm._e()]:_vm._e(),_c('div',{staticClass:"flexrow labels"},[_c('img',{attrs:{"src":"/images/minoseg_eso.png"}}),_c('br'),_vm._v(_vm._s(_vm.productData.fekezes)+" "),_c('img',{attrs:{"src":"/images/minoseg_fogyasztas.png"}}),_c('br'),_vm._v(_vm._s(_vm.productData.fogyasztas)+" "),_c('img',{attrs:{"src":"/images/minoseg_zaj.png"}}),_c('br'),_vm._v(_vm._s(_vm.productData.zaj)+" ")])],2),_c('div',{staticClass:"col-sm-6 description"},[_vm._m(0),_c('ul',{staticClass:"twoColumns bold"},[(_vm.productData.defektt)?_c('li',[_vm._v("Igen")]):_c('li',[_vm._v("Nem")]),(
                                        _vm.productData.xlfr &&
                                            _vm.productData.xlfr === 'XL'
                                    )?_c('li',[_vm._v(" Igen ")]):_c('li',[_vm._v("Nem")]),_c('li',[_vm._v(" "+_vm._s(_vm.weightIndex(_vm.productData.li))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.productData.si)+" "+_vm._s(_vm.speedIndex(_vm.productData.si))+" ")]),_c('li',[_vm._v(_vm._s(_vm.productData.m1nev))]),_c('li',[_vm._v(_vm._s(_vm.getM2Nev(_vm.productData.m2nev)))])])])]),_c('h4',[_vm._v("LEÍRÁS")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.productData.marka_leiras)}}),(
                            _vm.productData.globalPattern &&
                                _vm.productData.globalPattern.description
                        )?_c('p',{domProps:{"innerHTML":_vm._s(_vm.productData.globalPattern.description)}}):_c('p',{domProps:{"innerHTML":_vm._s(_vm.productData.minta_leiras)}}),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quantity),expression:"quantity"}],staticClass:"form-control list-input-padding",attrs:{"type":"number","id":"count","name":"count","step":"1","placeholder":"darab","min":"1"},domProps:{"value":(_vm.quantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.quantity=$event.target.value}}}),_c('button',{staticClass:"toCart transition",on:{"click":_vm.addCart}},[_vm._v(" Kosárba"),_c('i',{staticClass:"icon-basket"})])])])])])]),_c('section',{staticClass:"listSection listing"},[_c('div',{staticClass:"container"},[_c('div',{attrs:{"id":"pages"}},[_vm._m(1),(_vm.model && _vm.model.similar)?_vm._l((_vm.model.similar),function(item,index){return _c('ProductRow',{key:'product-' + index,attrs:{"twocolumn":'twocolumn',"no-compare":true,"product-data":item}})}):_vm._e()],2)])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"twoColumns first"},[_c('li',[_vm._v("Defekttűrő")]),_c('li',[_vm._v("Erősített oldalfal (XL)")]),_c('li',[_vm._v("Súly index")]),_c('li',[_vm._v("Sebesség index")]),_c('li',[_vm._v("Szezon")]),_c('li',[_vm._v("Gépjármű")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"headline"},[_c('h1',[_vm._v("Hasonló termékek")])])}]

export { render, staticRenderFns }